import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGivingFormData } from 'hooks';
import { trackEmbedVisit } from '../services/idonateSdk';

export const usePageVisitTracking = ({ sessionId }: { sessionId: string }) => {
  const { givingFormId } = useParams();
  const {
    campaignId,
    givingFormVersion,
    givingFormVariant,
    organizationId,
    referenceCode
  } = useGivingFormData();
  const [hasTrackedPageVisit, setHasTrackedPageVisit] = useState(false);

  const trackPageVisit = async () => {
    try {
      if (
        givingFormId !== undefined &&
        organizationId !== undefined &&
        campaignId !== undefined &&
        sessionId !== undefined
      ) {
        let variant = -1;

        if (givingFormVariant !== undefined) {
          variant = givingFormVariant === 'VariantA' ? 0 : 1;
        }

        trackEmbedVisit(
          givingFormId,
          organizationId,
          campaignId,
          sessionId,
          givingFormVersion || -1,
          variant
        );
      }

      const commonFields = {
        session_id: sessionId,
        embed_id: givingFormId,
        embed_type: 'giving-form',
        organization_id: organizationId,
        campaign_id: campaignId,
        embed_url: window.location.href,
        reference_code: referenceCode
      };
    } catch (err) {
      // For now, we are not going to bother the user with an error here
    } finally {
      setHasTrackedPageVisit(true);
    }
  };

  return {
    hasTrackedPageVisit,
    trackPageVisit
  };
};
