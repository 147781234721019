/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { GooglePay, recaptcha } from '@idonatedev/idonate-sdk';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SubmitDonationDataType } from 'services/donationService';
import { initPendo } from 'services/pendoService';
import { loadSiftLibrary } from 'services/siftService';
import { v4 as uuid } from 'uuid';
import useEventHub, {
  EditorEventTypes,
  EventHubEvent,
  ScrollPositions
} from 'components/EventHub';
import ThankYou from 'components/ThankYou/ThankYou';
import { useExtraFormInfo, useGivingFormData } from 'hooks';
import { useCreditCardValidation } from 'hooks/useCreditCardValidation';
import { usePageVisitTracking } from 'hooks/usePageVisitTracking';
import { useQueryParamMapper } from 'hooks/useQueryParamMapper';
import { theTheme } from 'theme';
import { AllPaymentOptions, Donation, IGivingFormConfig } from 'types';
import type { IGivingFormSchema } from '.';
import { createGivingFormSchema } from '.';
import './GivingForm.scss';
import { createGivingFormTheme } from './GivingForm.theme';
import { GivingFormElement } from './GivingFormElement';

const GivingForm = (): JSX.Element => {
  const { givingFormId } = useParams();
  const {
    config: givingFormConfig,
    overwriteConfig,
    loadingError,
    setGivingFormId,
    isLoaded,
    donorFees,
    doubleTheDonationKey,
    setReferrer,
    givingFormName,
    paymentGateway,
    organizationId
  } = useGivingFormData();
  const { creditCardCc, setCreditCardCc, creditCardCvv, setCreditCardCvv } =
    useCreditCardValidation();
  const { extraContext } = useExtraFormInfo();
  const [sessionId] = useState<string>(uuid());
  const { hasTrackedPageVisit, trackPageVisit } = usePageVisitTracking({
    sessionId
  });
  const [editMode, setEditMode] = useState<string>('');
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [donationComplete, setDonationComplete] = useState<boolean>(false);
  const [highlightedEditBlock, setHighlightedEditBlock] = useState<string>('');
  const [donationResponse, setDonationResponse] = useState<Donation | null>(
    null
  );
  const [donationObject, setDonationObject] =
    useState<SubmitDonationDataType | null>(null);
  const [recurringGiftPromptOpen, setRecurringGiftPromptOpen] = useState(false);

  const { schema: givingFormSchema, pages: givingFormSchemaPages } = useMemo(
    () =>
      createGivingFormSchema(
        givingFormConfig,
        {
          creditCardCc,
          creditCardCvv
        },
        paymentGateway
      ),
    [givingFormConfig, creditCardCc, creditCardCvv, paymentGateway]
  );

  const [currPageIndex, setCurrPageIndex] = useState<number>(0);

  const methods = useForm<IGivingFormSchema>({
    resolver:
      !!editMode || isPreviewMode ? undefined : yupResolver(givingFormSchema), // Prevent form validation while in edit mode
    mode: 'onBlur',
    context: extraContext
  });

  useQueryParamMapper(methods);

  const { eventHub } = useEventHub((initializeEventHub: any) => {
    if (initializeEventHub) {
      initializeEventHub.then((eventHubRef: any) => {
        if (eventHubRef.props?.mode === 'EDIT') {
          setEditMode('GivingForm');
        } else if (eventHubRef.props?.mode === 'PREVIEW') {
          setIsPreviewMode(true);
        }
        eventHubRef.subscribe(
          EditorEventTypes.ConfigurationUpdate,
          ({ payload }: EventHubEvent) => {
            overwriteConfig(payload as IGivingFormConfig);
            methods.reset();
            // when previewing a giving form that is part of
            // an A/B test, we need to ensure they are shown
            // the first page of the form when toggling between
            // A and B. This ensures that when user is viewing
            // the second page of one form and toggles to a form
            // that doesn't have a second page, they are not shown
            // an empty giving form
            // currently we should only be getting a config update
            // in preview mode if the user is toggling between
            // A and B variants
            if (eventHubRef.props?.mode === 'PREVIEW') {
              setCurrPageIndex(0);
            }
            return eventHubRef;
          }
        );
        eventHubRef.subscribe(
          EditorEventTypes.EditBlock,
          ({ payload }: EventHubEvent) => {
            // if the Recurring Gift Prompt is already enabled,
            // render the prompt when the corresponding section
            // of the element library is opened
            if (
              payload.id === 'recurringGiftPrompt' &&
              payload.config?.recurringGiftPrompt?.isEnabled
            ) {
              setRecurringGiftPromptOpen(true);
              // close the Recurring Gift Prompt when user returns
              // to the Root View of the element library (payload.id
              // is an empty string any time we return to the Root View)
            } else if (!payload.id) {
              setRecurringGiftPromptOpen(false);
            }
            setHighlightedEditBlock(payload.id);
            return eventHubRef;
          }
        );
        // the payload for this event is the isEnabled boolean from the Recurring Gift Prompt editor;
        // the prompt will open and close as the user enables or disables it in the element library
        eventHubRef.subscribe(
          EditorEventTypes.ToggleRecurringGiftPrompt,
          ({ payload }: EventHubEvent) => {
            setRecurringGiftPromptOpen(payload);
          }
        );
        eventHubRef.subscribe(EditorEventTypes.DeselectBlock, () => {
          setHighlightedEditBlock('');
          return eventHubRef;
        });

        eventHubRef.subscribe(EditorEventTypes.SetPreviewGivingForm, () => {
          setEditMode('GivingForm');
          setHighlightedEditBlock('');
        });

        eventHubRef.subscribe(EditorEventTypes.SetPreviewThankYou, () => {
          setEditMode('ThankYou');
          setHighlightedEditBlock('');
        });

        // referrer of parent window
        eventHubRef.subscribe(EditorEventTypes.Referrer, (x: any) =>
          setReferrer(x.payload)
        );

        const monitorBodyHeight = () => {
          const targetNode = document.body;
          let lastHeight = targetNode.offsetHeight;
          if (window.ResizeObserver) {
            const observer = new ResizeObserver((entries: any) => {
              const height = entries[0].target.offsetHeight;
              if (height && height !== lastHeight) {
                eventHubRef.emit(
                  EditorEventTypes.BodyHeightUpdate,
                  `${height}px`
                );
                lastHeight = height;
              }
            });
            observer.observe(targetNode);
          }

          if (lastHeight) {
            eventHubRef.emit(
              EditorEventTypes.BodyHeightUpdate,
              `${lastHeight}px`
            );
          }
        };

        monitorBodyHeight();
      });
    }
  });

  useEffect(() => {
    // Not `emit` since this message is sent prior to EventHub initialization
    window.parent.postMessage({ name: EditorEventTypes.PageReady }, '*');

    // initialize google pay
    try {
      GooglePay.injectScript();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        'There was a problem loading Google Pay script. Most likely it has already been injected.'
      );
    }
  }, []);

  useEffect(() => {
    // If form is live record page visit on load
    if (isLoaded && !editMode) {
      // Inject the captcha script only when not in preview mode
      if (!isPreviewMode) {
        recaptcha.injectScript();

        if (!hasTrackedPageVisit) {
          trackPageVisit();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, isPreviewMode, isLoaded]);

  useEffect(() => {
    const paymentOption = methods.getValues('paymentOption');
    if (
      paymentOption === AllPaymentOptions.CREDIT ||
      paymentOption === AllPaymentOptions.DEBIT
    ) {
      if (window.Spreedly && paymentGateway?.backendName === 'spreedly') {
        if (
          creditCardCc === 'untouched' &&
          methods.formState.errors?.creditCard?.cc
        ) {
          window.Spreedly.validate();
          setCreditCardCc('error');
        }

        if (
          creditCardCvv === 'untouched' &&
          methods.formState.errors?.creditCard?.cvv
        ) {
          window.Spreedly.validate();
          setCreditCardCvv('error');
        }
      }
      if (paymentGateway?.backendName === 'card_connect') {
        // TODO: should manually validate credit card info.
      }
    }
  }); // TODO: we probably need a dependency array here

  useEffect(() => {
    if (givingFormId) {
      setGivingFormId(givingFormId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingFormId]);

  useEffect(() => {
    if (isLoaded && eventHub) {
      eventHub.emit(EditorEventTypes.PageLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, eventHub]);

  useEffect(() => {
    if (organizationId) {
      // inject Pendo script once organization info is loaded
      initPendo(organizationId);
    }
  }, [organizationId]);

  useEffect(() => {
    loadSiftLibrary(sessionId);
  });

  if (loadingError) {
    // May want design to create a 404-esque page to display in the unfortunate event we cannot load a giving form for the donor
    return <div>Unexpected error</div>;
  }

  if (!givingFormConfig || !isLoaded) {
    // These should never be seen, but just incase it's visible in error want to show it is trying to load/pending data
    return givingFormId?.includes('edit') ? (
      <div>Waiting for data...</div>
    ) : (
      <div>Loading...</div>
    );
  }

  const emitThankYouEditEvent = (id: string) => {
    setHighlightedEditBlock(id);
    eventHub.emit(EditorEventTypes.EditThankYouBlock, { id });
  };

  const emitThankYouDropEvent = (blockOrder: string[]) => {
    eventHub.emit(EditorEventTypes.ThankYouBlockOrderUpdate, { blockOrder });
  };

  const emitFormPageChange = (scrollPosition: ScrollPositions) => {
    eventHub?.emit(EditorEventTypes.FormPageChange, scrollPosition);
  };

  const theme = createGivingFormTheme(theTheme, givingFormConfig);

  return (
    <>
      {givingFormName && (
        <Helmet>
          <title>{givingFormName}</title>
        </Helmet>
      )}
      {/* wraps the giving form in a new theme provider with the newly created theme */}
      <ThemeProvider theme={theme}>
        {/**
         * Here global styles maps some CSS variables to the configured colors of the giving
         * form's theme. This is so we can access the theme in our SCSS files!
         */}
        <GlobalStyles
          styles={{
            ':root': {
              '--primary-color': theme.palette.primary.main,
              '--accent-color': theme.palette.accent.main,
              '--body-font': givingFormConfig.theme.font.formBody
            }
          }}
        />
        {donationComplete || editMode === 'ThankYou' ? (
          <ThankYou
            givingFormSubmission={donationObject}
            donationResponse={donationResponse}
            isEditMode={!!editMode}
            isPreviewMode={isPreviewMode}
            highlightedEditBlock={highlightedEditBlock}
            emitThankYouEditEvent={emitThankYouEditEvent}
            emitThankYouDropEvent={emitThankYouDropEvent}
          />
        ) : (
          <GivingFormElement
            currPageIndex={currPageIndex}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            donorFees={donorFees!}
            doubleTheDonationKey={doubleTheDonationKey}
            emitFormPageChange={emitFormPageChange}
            eventHub={eventHub}
            givingFormConfig={givingFormConfig}
            givingFormSchemaPages={givingFormSchemaPages}
            highlightedEditBlock={highlightedEditBlock}
            isEditMode={!!editMode}
            isPreviewMode={isPreviewMode}
            methods={methods}
            sessionId={sessionId}
            setCurrPageIndex={setCurrPageIndex}
            setDonationComplete={setDonationComplete}
            setHighlightedEditBlock={setHighlightedEditBlock}
            setDonationObject={setDonationObject}
            setDonationResponse={setDonationResponse}
            recurringGiftPromptOpen={recurringGiftPromptOpen}
            setRecurringGiftPromptOpen={setRecurringGiftPromptOpen}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default GivingForm;
