import {
  ApplePay,
  Client,
  GooglePay,
  GooglePayConfig
} from '@idonatedev/idonate-sdk';
import { handleCFChallenge as ChallengeHandler } from '@idonatedev/idonate-sdk/dist/cloudflare-challenge-handler';
import iDonateClient from '@idonatedev/idonate-sdk/dist/idonate-client';
import { resolvedEnvironment } from './environment';

export const handleCFChallenge = ChallengeHandler;

export const sdkClientPromise = async (organizationId: string) =>
  new Client(organizationId, {
    ...resolvedEnvironment.sdkConfig
  });

// GooglePay
export const getGooglePayHandler = async (
  organizationId: string,
  organizationName: string,
  digitalWalletMerchantId: string
) => {
  const googlePayConfig: GooglePayConfig = {
    paymentDataRequest: {
      merchantInfo: {
        // a merchant ID is available for a production environment after approval from Google
        // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
        merchantId: resolvedEnvironment.googlePayMerchantIdNew || '',
        merchantName: organizationName
      }
    },
    paymentOptions: {
      environment: (resolvedEnvironment.sdkConfig.enableSandboxMode
        ? 'TEST'
        : 'PRODUCTION') as GooglePayConfig['paymentOptions']['environment']
    },
    cardConnectMerchantId: digitalWalletMerchantId
  };

  const sdk = new Client(organizationId, {
    ...resolvedEnvironment.sdkConfig
  });

  const googlePay: GooglePay = new GooglePay(googlePayConfig);
  const client = await googlePay.getGooglePaymentClient();

  return {
    sdk,
    googlePay,
    client,
    sandboxMode: resolvedEnvironment.sdkConfig.enableSandboxMode || false
  };
};

export interface GooglePayHandlerType {
  sdk: iDonateClient;
  googlePay: GooglePay;
  client: google.payments.api.PaymentsClient;
  sandboxMode: boolean;
}

// ApplePay
export let isApplePaySupported = false;
try {
  isApplePaySupported = ApplePay.isSupported();
} catch (e) {
  // No action needed, support is false
}

export const getApplePayData = async (organizationId: string) => {
  // const envConfig = await environment;
  const client = new Client(organizationId, {
    ...resolvedEnvironment.sdkConfig
  });

  return {
    client,
    resolvedEnvironment,
    embedBaseUrl:
      resolvedEnvironment.embedBaseUrl || 'https://api.qa-idonate.com/embed' // TODO: Remove hard coded URL once env vars are set up
  };
};

export const trackEmbedVisit = (
  embedId: string,
  organizationId: string,
  campaignId: string,
  visitorId: string,
  version: number,
  variant: number
) => {
  // instantiate client to assist in building the Embed API URL
  const client = new Client(organizationId, {
    ...resolvedEnvironment.sdkConfig
  });

  const trackVisitUrl = `${client.config.apiBaseUrl}/embed/metrics/visit/${embedId}`;

  fetch(trackVisitUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      visitor_id: visitorId,
      organization_id: organizationId,
      campaign_id: campaignId,
      variant,
      version
    })
  }).then(); // do nothing with response
};
